// js
import "../../node_modules/slick-carousel/slick/slick";

// scss
import "../scss/frontend/components/_slider.scss";

var SliderOptions = {
    arrows: true,
    autoplay: false,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    variableWidth: true,
    dots: false,
    responsive: [
        {
            breakpoint: 667,
            name: "sm",
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 568,
            name: "xs",
            settings: {
                slidesToShow: 1,
            },
        },
        {
            breakpoint: 0,
            name: "xxxs",
            settings: {
                slidesToShow: 1,
            },
        }],
    slidesToScroll: 1,
    slidesToShow: 3,
};

export default SliderOptions;
