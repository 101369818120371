// scss
import "../scss/home.scss";
import "bootstrap/js/dist/modal";

// js
import SliderOptions from "./slider.js";

document.addEventListener('readystatechange', event => {
    if (event.target.readyState === 'complete') {
        $('#slick').slick(SliderOptions);
    }
});
